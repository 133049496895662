<template>
  <DefaultLayout>
    <template v-slot:header>
      <v-spacer />
      <v-btn @click="backToList">{{ $t("userdelete.back") }}</v-btn>
    </template>

    <v-layout>
      <v-flex>
        <UserRelations ref="userRelations" />
      </v-flex>
    </v-layout>
  </DefaultLayout>
</template>

<script>
import DefaultLayout from "@/layouts/default.vue";
import UserRelations from "@/components/users/userrelations.vue";

export default {
  name: "usermngt",
  components: {
    DefaultLayout,
    UserRelations,
  },
  methods: {
    backToList: function() {
      this.$router.push("/admin/users");
    }
  }
};
</script>
