<template>
  <v-container>
    <h1>{{ $t("userdelete.pageTitle") }}</h1>

    <v-alert v-if="relationTree.length > 0" text icon="mdi-alert" border="left" color="red" block class="my-5" ref="relTree">
      {{ $t("userdelete.info.warning", { ct: relationTree.length} ) }}
    </v-alert>

    <v-alert v-if="relationTree.length > 0" text icon="mdi-help" border="left" block class="my-5" ref="relTree">
      {{ $t("userdelete.info.projectDelete") }}
    </v-alert>

    <div v-else>
      <v-alert text icon="mdi-help" border="left" color="ok" block class="my-5" ref="relTree">
        {{ $t("userdelete.info.ok") }}
      </v-alert>
      <v-btn color="primary" @click="deleteUser">{{ $t("userdelete.delete") }}</v-btn>
    </div>

    <v-treeview v-model="relationTreeModel" :items="relationTree" item-key="uid" :open="relationTreeInitiallyOpen" open-all dense>
      <template v-slot:prepend="{ item, open }">
        <v-icon v-if="!item.type">
          {{ open ? "mdi-folder-open" : "mdi-folder" }}
        </v-icon>
        <v-icon v-else @click="copyToClipboard(item.id)">
          {{ relationTreeIcons[item.type] }}
        </v-icon>
      </template>
      <template v-slot:append="{ item }">
        <div v-if="item.ownerId || item.assigneeId">
          <v-btn class="float-right" text color="red" @click="deleteResource(item.type, item.id)"><v-icon>mdi-delete</v-icon></v-btn>
          
          <v-btn v-if="item.ownerId" class="float-right" text color="green" @click="changeUser(item.type, item.id, 'ownerId', item.ownerId)"
            ><v-icon>mdi-call-split</v-icon></v-btn
          >
          <v-btn v-else class="float-right" text color="green" @click="changeUser(item.type, item.id, 'assigneeId', item.assigneeId)"
            ><v-icon>mdi-arrow-right-circle</v-icon></v-btn
          >
          
          <v-autocomplete
            v-if="item.ownerId"
            v-model="item.ownerId"
            :items="owners"
            hide-selected
            item-text="name"
            item-value="id"
            dense
          ></v-autocomplete>
          <v-autocomplete
            v-else
            v-model="item.assigneeId"
            :items="owners"
            hide-selected
            item-text="name"
            item-value="id"
            dense
          ></v-autocomplete>
        </div>
      </template>
    </v-treeview>
    <pre v-if="false">{{ relationTree }}</pre>
  </v-container>
</template>

<script>
import axios from "axios";
import { formatMixin, listMixin } from "@/helpers/mixins";

export default {
  mixins: [formatMixin, listMixin],

  data() {
    return {
      search: "",
      owners: [],
      relationTreeInitiallyOpen: ["projects", "tasks", "appointments"],
      relationTree: [],
      relationTreeModel: [],
      relationTreeIcons: {
        projects: "mdi-home-account",
        tasks: "mdi-calendar-check",
        appointments: "mdi-calendar",
        notes: "mdi-pencil-outline",
      },
    };
  },

  components: {},

  computed: {},

  created() {
    this.loadOwners();
    this.loadUserRelations();
  },

  methods: {
    loadOwners: function () {
      axios
        .get(`/api/users`)
        .then((response) => {
          this.owners = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    loadUserRelations: function () {
      this.$store.dispatch("loading", true);
      axios
        .get(`/api/users/${this.$route.params.id}/relations`)
        .then((response) => {
          this.relationTree = response.data;
          this.$store.dispatch("loading", false);
        })
        .catch((e) => {
          console.log(e);
          this.$store.dispatch("loading", false);
        });
    },
    changeUser: function (type, id, field, newId) {
      let data = {};
      data[field] = newId 
      axios
        .post(`/api/${type}/${id}`, data)
        .then((response) => {
          this.relationTree = this.relationTree.filter((r) => r.id != id);
          console.log("OK", response);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    deleteResource: function (type, id) {
      axios
        .delete(`/api/${type}/${id}`)
        .then((response) => {
          this.relationTree = this.relationTree.filter((r) => r.id != id);
          console.log("OK", response);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    deleteUser: function () {
      axios
        .delete(`/api/users/${this.$route.params.id}`)
        .then((response) => {
          this.$router.push("/admin/users");
          console.log("OK", response);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    copyToClipboard: function (text) {
      window.prompt("Copy to clipboard: Ctrl+C, Enter", text);
    },
  },
};
</script>
